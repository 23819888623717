<template>
  <div class="property-list-container">
    <Header
      :details="{
        back: false,
        title: 'Projects',
      }"
    />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex sub-header-options">
          <div class="search"><input type="text" v-model="search" :placeholder="$t('overall.search')" /></div>
          <router-link :to="{ name: 'create-project' }" class="cta align-right">
            <plusSVG />
            {{ $t("projects.create") }}
          </router-link>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li @click="orderListBy('name', projects)" class="table-list__line">
            <button>{{ $t("overall.name") }}</button>
          </li>
          <li @click="orderListBy('end_date', projects)" class="table-list__line">
            <button>{{ $t("overall.completion_date") }}</button>
          </li>
          <li @click="orderListBy('builder.name', projects)" class="table-list__line">
            <button>{{ $t("overall.builder") }}</button>
          </li>
          <li @click="orderListBy('address.city', projects)" class="table-list__line">
            <button>{{ $t("overall.location") }}</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="p in elementsToDisplay" :key="p.token">
              <router-link :to="{ name: 'edit-project', params: { token: p.token } }">
                <div class="table-list__line">{{ p.name ? p.name : "-" }}</div>
                <div class="table-list__line">{{ p.end_date ? parseBeautifulDate(p.end_date) : "-" }}</div>
                <div class="table-list__line">{{ p.builder ? p.builder.name : "-" }}</div>
                <div class="table-list__line">{{ p.address && p.address.city ? p.address.city : "-" }}</div>
              </router-link>
            </div>
          </template>
          <span class="no-element" v-else>{{ $t("projects.loading_projects") }}</span>
          <span class="no-element" v-if="projects.length < 1 && !loading">{{ $t("projects.no_project") }}</span>
        </div>
      </div>
      <Pagination v-if="projects.length" :value="projects" @changeCurrentPage="elementsToDisplay = $event" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import plusSVG from "@/assets/img/plus.svg";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  components: { plusSVG },
  name: "ProjectsList",
  computed: {
    numberElements() {
      return this.projects.length;
    },
    numberPagination() {
      return Math.round(this.numberElements / this.elementPerPage);
    },
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.loading = true;
        this.$axios.get("/api/projects/search?search=" + this.search).then((res) => {
          this.$set(this, "projects", res.data);
          this.loading = false;
          this.timer = null;
          if (!this.properties.length) {
            this.elementsToDisplay = [];
          }
        });
      }, 300);
    },
  },
  data() {
    return {
      timer: null,
      projects: [],
      filters: {},
      elementsToDisplay: [],
      currentPagination: 1,
      pagination: 1,
      elementPerPage: 3,
      search: "",
      loading: true,
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  methods: {
    popupAddProject() {
      modalBus.$emit("change", {
        component: "AddProject",
      });
      modalBus.$emit("set-size", {
        width: "400px",
        height: "auto",
        "margin-top": "50px",
      });
      modalBus.$emit("toggle");
    },
  },
  mounted() {
    this.$axios.get("/api/projects").then((res) => {
      this.projects = res.data.projects;
      this.loading = false;
    });
  },
});
</script>

<style lang="scss" scoped></style>
